















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import { Utils } from '@/app/infrastructures/misc/Utils'
import controller from '@/app/ui/controllers/ManageCourierController'
import { ManageCourierOnLeave } from '@/domain/entities/ManageCourier'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { Dictionary } from 'vue-router/types/router'
import {
  IHeaderCell,
  ITableCell,
} from '@/data/infrastructures/misc/interfaces/manageCourier'

interface IParams {
  search: string
  courierType: IOptions
  leaveType: IOptions
  dateLeave: Date | null
  page: number
  perPage: number
}

interface IOptions {
  label: string
  value: string | number | boolean
}

@Component({
  components: {
    TextInput,
    DropdownSelect,
    DateTimePickerV2,
    DataTableV2,
    EmptyState,
    PaginationNav,
    LoadingOverlay,
  },
})
export default class CourierLeavePage extends Vue {
  controller = controller
  listData: ManageCourierOnLeave[] = []
  courierTypeOptions = [
    { label: 'Semua', value: '' },
    { label: 'KVP', value: 'KVP' },
    { label: 'POS', value: 'POS' },
    { label: 'TRUCK', value: 'TRUCK' },
  ]

  leaveTypeOptions = [
    { label: 'Semua', value: '' },
    { label: 'Seharian', value: false },
    { label: 'Setengah Hari', value: true },
  ]

  parameters: IParams = {
    search: '',
    courierType: <IOptions>{},
    leaveType: <IOptions>{},
    dateLeave: new Date(),
    page: 1,
    perPage: 10,
  }

  headers = [
    this.headerCellMapper('No', '50px', '0px', '0px'),
    this.headerCellMapper('Kurir ID', '100px'),
    this.headerCellMapper('Nama Kurir', '200px'),
    this.headerCellMapper('No. Handphone', '170px'),
    this.headerCellMapper('Jenis Kurir', '120px'),
    this.headerCellMapper('Plat Nomor', '130px'),
    this.headerCellMapper('POS', '200px'),
    this.headerCellMapper('Tanggal Cuti/Libur', '200px'),
    this.headerCellMapper('Jam Cuti', '200px'),
    this.headerCellMapper('Tipe Cuti/Libur', '200px'),
    this.headerCellMapper('Diajukan Oleh', '151px'),
  ]

  perPageOptions: IOptions[] = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  created(): void {
    const query = <Record<string, never>>this.$route.query
    this.parameters = {
      search: query.keyword,
      page: query.page || 1,
      perPage: query.perPage || 10,
      dateLeave: query.dateLeave
        ? this.checkValidDate(new Date(query.dateLeave))
        : new Date(),
      courierType:
        this.courierTypeOptions.filter(
          option => option.value === query.courierType
        )[0] || this.courierTypeOptions[0],
      leaveType:
        this.leaveTypeOptions.filter(
          option => option.value.toString() === query.isCustomLeave
        )[0] || this.leaveTypeOptions[0],
    }

    this.fetchManageCourierOnLeveList(true)
  }

  get params(): Record<string, string | number> {
    return {
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      keyword: this.parameters.search,
      dateLeave: this.parameters?.dateLeave
        ? Utils.formatDateWithIDLocale(
            <string>this.parameters?.dateLeave?.toISOString(),
            'YYYY-MM-DD'
          )
        : Utils.formatDateWithIDLocale(new Date().toISOString(), 'YYYY-MM-DD'),
      courierType: this.parameters.courierType
        ? <string>this.parameters.courierType.value
        : '',
      isCustomLeave: this.parameters.leaveType
        ? <string>this.parameters.leaveType.value
        : '',
    }
  }

  get courierOnLeaveData(): ITableCell[][] {
    return this.listData.map((courier, index: number) => [
      this.tableCellMapper(
        index + 1 + this.parameters.perPage * (this.parameters.page - 1),
        '50px',
        '0px',
        '0px'
      ),
      this.tableCellMapper(courier.courierId || '', '100px'),
      this.tableCellMapper(courier.fullName || '-', '200px'),
      this.tableCellMapper(courier.phoneNumber || '-', '170px'),
      this.tableCellMapper(courier.courierTypeValid || '-', '120px'),
      this.tableCellMapper(courier.vehiclePlate || '-', '130px'),
      this.tableCellMapper(courier.partnerName || '-', '200px', '20px', '4px'),
      this.tableCellMapper(
        Utils.formatDateWithIDLocale(
          <string>courier.courierOnLeave?.dateLeave,
          'DD MMMM'
        ),
        '200px'
      ),
      this.tableCellMapper(
        `${Utils.formatDateWithIDLocale(
          <string>courier.courierOnLeave?.startTime,
          'HH:mm'
        )} - ${Utils.formatDateWithIDLocale(
          <string>courier.courierOnLeave?.endTime,
          'HH:mm'
        )}`,
        '200px'
      ),
      this.tableCellMapper(
        this.formatedLeaveType(<boolean>courier.courierOnLeave?.isCustomLeave),
        '200px'
      ),
      this.tableCellMapper(
        this.formatedRequestedBy(<string>courier.requestedBy),
        '151px'
      ),
    ])
  }

  public fetchManageCourierOnLeveList(reset = false): void {
    if (reset) {
      this.parameters.page = 1
    }

    const params: Record<string, string | number> = {
      ...this.params,
    }

    if (params.isCustomLeave === '') {
      delete params.isCustomLeave
    }

    if (params.dateLeave === '') {
      params.dateLeave = Utils.formatDateWithIDLocale(
        new Date().toISOString(),
        'YYYY-MM-DD'
      )
    }

    this.controller.getManageCourierOnLeaveList(params)
  }

  public onFilter(): void {
    this.fetchManageCourierOnLeveList(true)
  }

  public onFilterCourierType(value: IOptions): void {
    if (!value) {
      this.parameters.courierType = this.courierTypeOptions[0]
    }
    this.fetchManageCourierOnLeveList(true)
  }

  public onFilterLeaveType(value: IOptions): void {
    if (!value) {
      this.parameters.leaveType = this.leaveTypeOptions[0]
    }
    this.fetchManageCourierOnLeveList(true)
  }

  public onSearch = Utils.debounce((value: string) => {
    if ((value && value.length > 2) || !value) {
      this.fetchManageCourierOnLeveList(true)
    }
  }, 500)

  public onChangePerPage(perPage: number): void {
    this.parameters.perPage = perPage
    this.fetchManageCourierOnLeveList(true)
  }

  private checkValidDate(value: Date): Date | null {
    if (value.toString() === 'Invalid Date') {
      return null
    } else {
      return value
    }
  }

  private formatedLeaveType(value: boolean): string {
    if (value) {
      return 'Setengah Hari'
    } else {
      return 'Seharian'
    }
  }

  public formatedRequestedBy(value: string): string {
    if (value === 'ADMIN') {
      return 'Admin'
    } else {
      return 'Kurir'
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string,
    paddingRight?: string,
    paddingLeft?: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
        paddingRight: paddingRight || '0px',
        paddingLeft: paddingLeft || '20px',
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string,
    paddingLeft?: string,
    paddingRight?: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
        paddingRight: paddingRight || '0px',
        paddingLeft: paddingLeft || '24px',
      },
    }
  }

  @Watch('params')
  onParamsChanged(
    val: Dictionary<string | (string | null)[] | null | undefined> | undefined
  ): void {
    this.$router.replace({
      query: {
        ...val,
        dateLeave:
          val?.dateLeave ||
          Utils.formatDateWithIDLocale(new Date().toISOString(), 'YYYY-MM-DD'),
      },
    })
    document.title =
      'Daftar Cuti/Libur | Content Management System | Lionparcel'
  }

  @Watch('controller.manageCourierOnLeaveData')
  onChangeListData(val: ManageCourierOnLeave[]): void {
    this.listData = val
  }
}
